import React, { useEffect } from "react";
import Header from "./Header";
import { Grid } from "@mui/material";
import Sidebar from "./Sidebar";
import ReactSwitch from "react-switch";
import { Link } from "react-router-dom";
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import {
  getMethod,
  postMethod,
  exchangegetMethod,
} from "../core/sevice/common.api";
// import { toast } from "react-toastify";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import moment from "moment";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart, Pie, Cell
} from "recharts";



function Dashboard() {
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalTrade, setTotalTrade] = useState(0);

  const [totalProfit_1, setTotalProfit_1] = useState(0);
  const [totalTrade_1, setTotalTrade_1] = useState(0);

  const [totalProfit_2, setTotalProfit_2] = useState(0);
  const [totalTrade_2, setTotalTrade_2] = useState(0);


  const [totalProfit_3, setTotalProfit_3] = useState(0);
  const [totalTrade_3, setTotalTrade_3] = useState(0);
  
  const [todayProfit, setTodayProfit] = useState("");
  const [todayTrade, setTodayTrade] = useState("");
  const [yesterdayProfit, setYesterdayProfit] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loader_icon, setloader_icon, loader_iconref] = useState(false);
  const [MarketList, setMarketList, MarketListref] = useState([]);
  const [recentProfit, setrecentProfit, recentProfitref] = useState([]);
  const [todayInvest, setTodayInvest] = useState("");
  const [yesterdayInvest, setyesterdayInvest] = useState("");
  const [totalInvest, setTotalInvest] = useState("");
  
  const [avgTradeprofit, setAvgTradeprofit] = useState("");
  const [monthTrade, setmonthTrade] = useState("");
  const [adminDetails, setAdminDetails, adminDetailsref] = useState("");
  const [loginCounts, setloginCounts, loginCountsref] = useState(0);

  const [todayPercent, settodayPercent] = useState("");
  const [yesterdayPercent, setyesterdayPercent] = useState("");
  const [totalPercent, settotalPercent] = useState("");
  const [todayTrades, settodayTrades] = useState(0);
  const [totalCapital, settotalCapital] = useState(0);
  const [remainingCapital, setremainingCapital] = useState(0);
  const [totalPortfolio, settotalPortfolio] = useState(0);

  const [isActive, setIsActive] = useState(false);

  const [exchangeBalance_1, setexchangeBalance_1] = useState("");
  const [exchangeBalance_2, setexchangeBalance_2] = useState("");
  const [exchangeBalance_3, setexchangeBalance_3] = useState("");
  const [exchangeBalance_4, setexchangeBalance_4] = useState("");


  const data = [
    ["Task", "Hours per Day"],
    ["Work", 9],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const options = {
    title: "My Daily Activities",
  };

  const [chartdata, setchartdata, chartdataref] = useState([
    { profit: 1, date: 0 },
    { profit: 2, date: 0 },
    { profit: 4, date: 0 },
    { profit: 5, date: 0 },
    { profit: 6, date: 0 },
  ]);

  const [actives, setactives] = useState("");

  const [activeIndex, setActiveIndex] = useState(-1);

  const [piedata, setpiedata, piedataref] = useState([]);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#0088FE'];

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

  useEffect(() => {
    getTotalProfit();
    getTotalProfit_1();
    getTotalProfit_2();
    getTotalProfit_3();
    // getTodayProfit();
    // getYesterdayProfit();
    // getTotalBalance();
    // getBotStatus();
    // getLoginHistory(1);
    // prediction("");
    // getCoinMarket();
    // recent_history();
    getexchangeBalance_1();
    getexchangeBalance_2();
    getexchangeBalance_3();
    //getexchangeBalance_4();
  }, [0]);

  const getBotStatus = async () => {
    try {
      var data = {
        apiUrl: apiService.getbotstatus,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp.status == true) {
        const details = resp.data.status;
        if (details == "Active") {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    } catch (error) {}
  };

  const getTotalProfit = async () => {
    try {
      var data = {
        apiUrl: apiService.get_profit_account1,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        // setTotalProfit(resp.overall_profit);
        setTotalTrade(resp.totaltrades);
       
        var profit_percent = (+resp.overall_profit / +resp.overall_invest) * 100;
        // var profit_percent = (+resp.overall_profit / 800) * 100;
        profit_percent = (!isNaN(profit_percent)) ? profit_percent : 0;
        settotalPercent(profit_percent);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };


  const getTotalProfit_1 = async () => {
    try {
      var data = {
        apiUrl: apiService.get_profit_account2,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        // setTotalProfit_1(resp.overall_profit);
        setTotalTrade_1(resp.totaltrades);
       
        var profit_percent = (+resp.overall_profit / +resp.overall_invest) * 100;
        // var profit_percent = (+resp.overall_profit / 800) * 100;
        profit_percent = (!isNaN(profit_percent)) ? profit_percent : 0;
       // settotalPercent(profit_percent);
        setTotalProfit_1(profit_percent);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTotalProfit_2 = async () => {
    try {
      var data = {
        apiUrl: apiService.get_profit_account3,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
       // setTotalProfit_2(resp.overall_profit);
        setTotalTrade_2(resp.totaltrades);
       
        var profit_percent = (+resp.overall_profit / +resp.overall_invest) * 100;
        // var profit_percent = (+resp.overall_profit / 800) * 100;
        profit_percent = (!isNaN(profit_percent)) ? profit_percent : 0;
       // settotalPercent(profit_percent);
        setTotalProfit_2(profit_percent);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTotalProfit_3 = async () => {
    try {
      var data = {
        apiUrl: apiService.get_profit_account4,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        // setTotalProfit_3(resp.overall_profit);
        setTotalTrade_3(resp.totaltrades);
       
        var profit_percent = (+resp.overall_profit / +resp.overall_invest) * 100;
        // var profit_percent = (+resp.overall_profit / 800) * 100;
        profit_percent = (!isNaN(profit_percent)) ? profit_percent : 0;
        //settotalPercent(profit_percent);
        setTotalProfit_3(profit_percent);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTodayProfit = async () => {
    try {
      var data = {
        apiUrl: apiService.gettodayprofit,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        setTodayProfit(resp.total_profit);
        setTodayTrade(resp.totaltrades);
        setTodayInvest(resp.total_invest);
        var profit_percent = (+resp.total_profit / +resp.total_invest) * 100;
        profit_percent = !isNaN(profit_percent) ? profit_percent : 0;
        settodayPercent(profit_percent);
        settodayTrades(resp.total_trades);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getYesterdayProfit = async () => {
    try {
      var data = {
        apiUrl: apiService.getyesterdayprofit,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        setYesterdayProfit(resp.total_profit);
        setyesterdayInvest(resp.total_invest);
        var profit_percent = (+resp.total_profit / +resp.total_invest) * 100;
        profit_percent = !isNaN(profit_percent) ? profit_percent : 0;
        setyesterdayPercent(profit_percent);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTotalBalance = async () => {
    try {
      var data = {
        apiUrl: apiService.gettotalbalance,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp) {
        setTotalBalance(resp.balance.balance);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getLoginHistory = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 5,
        page: count,
      };
      var data = {
        apiUrl: apiService.getadminlogin,
        payload: payload,
      };
      setButtonLoader(true);
      var resp = await postMethod(data);
      setButtonLoader(false);
      if (resp) {
        console.log(resp, "admin login details");
        setAdminDetails(resp.data);
        setloginCounts(resp.count);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageClick_trade = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    getLoginHistory(currentPage);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = async (val) => {
    if (val == true) {
      var obj = {
        status: "Active",
      };
    } else {
      var obj = {
        status: "Inactive",
      };
    }
    var data = {
      apiUrl: apiService.botstatus,
      payload: obj,
    };
    setIsLoading(true);
    var resp = await postMethod(data);
    setIsLoading(false);
    if (resp) {
      toast.success(resp.Message);
      getBotStatus();
    }
  };

  //===================Chart data============================//
  const prediction = async (value) => {
    setactives(value);
    var now = new Date();
    var fdate = new Date();
    if (value == "") {
      fdate = new Date();
    } else if (value == "day") {
      var week = new Date(now);
      week.setDate(now.getDate() - 7);
      var weekIST = new Date(week);
      weekIST.setHours(weekIST.getHours() + 5);
      weekIST.setMinutes(weekIST.getMinutes() + 30);
      fdate = weekIST;
    } else if (value == "month") {
      var month = new Date(now);
      month.setDate(now.getDate() - 30);
      var monthIST = new Date(month);
      monthIST.setHours(monthIST.getHours() + 5);
      monthIST.setMinutes(monthIST.getMinutes() + 30);
      fdate = monthIST;
    } else if (value == "month3") {
      var month3 = new Date(now);
      month3.setDate(now.getDate() - 90);
      var month3IST = new Date(month3);
      month3IST.setHours(month3IST.getHours() + 5);
      month3IST.setMinutes(month3IST.getMinutes() + 30);
      fdate = month3IST;
    } else if (value == "year") {
      var yesrDate = new Date(now);
      yesrDate.setDate(now.getDate() - 365);
      var yesrDateIST = new Date(yesrDate);
      yesrDateIST.setHours(yesrDateIST.getHours() + 5);
      yesrDateIST.setMinutes(yesrDateIST.getMinutes() + 30);
      fdate = yesrDateIST;
    }
    console.log("Due Date:", now);
    console.log("Due Date:", fdate);
    var obj = {
      fdate: fdate,
      tdate: new Date(),
    };

    var data = {
      apiUrl: apiService.getchartData,
      payload: obj,
    };
    setloader_icon(true);
    var resp = await postMethod(data);
    setloader_icon(false);
    if (resp.status == true) {
      setchartdata(resp.data);
    } else {
      setchartdata([
        { profit: 0, date: 0 },
        { profit: 0, date: 0 },
        { profit: 0, date: 0 },
        { profit: 0, date: 0 },
        { profit: 0, date: 0 },
      ]);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("payload",payload)
    // console.log("label",label)
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p
            className="label"
            style={{ color: "#fff", fontWeight: "bold" }}
          >{`${payload[1].value} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const getCoinMarket = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var data = {
        apiUrl: apiService.getmarkets + `?page=${count}`,
      };
      // setIsLoading(true);
      var resp = await getMethod(data);
      // setIsLoading(false);
      if (resp.status == true) {
        setMarketList(resp.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const recent_history = async () => {
    try {
      var data = {
        apiUrl: apiService.recent_profit,
      };

      var resp = await getMethod(data);

      if (resp.status == true) {
        setrecentProfit(resp.data);
      }
    } catch (err) {}
  };

  const handleBot = (value) => {
    if (value == true) {
      setChecked(true);
      setIsActive(true);
      toast.success("Bot Is Activated");
    } else {
      setChecked(false);
      setIsActive(false);
      toast.error("Bot Is Deactivated");
    }
  };

  const getexchangeBalance_1 = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance_1,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      if (resp.status == true) {
        setIsLoading(false);
        setexchangeBalance_1(resp);
        let balance_percentage =  (+resp.fund_balance / +resp.balance) * 100;
        setTotalProfit(balance_percentage);
      }
      else
      {
        setIsLoading(false);
        setexchangeBalance_1({
          balance: 0,
          fund_balance: 0,
          date: moment(new Date().getTime()).format("lll")
        });
        setTotalProfit(0);
      }
    } catch (error) {}
  };



  const getexchangeBalance_2 = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance_2,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      if (resp.status == true) {
        setIsLoading(false);
        setexchangeBalance_2(resp);
        let balance_percentage =  (+resp.fund_balance / +resp.balance) * 100;
       //setTotalProfit_1(balance_percentage);
      }
      else
      {
        setIsLoading(false);
        setexchangeBalance_2({
          balance: 0,
          fund_balance: 0,
          date: moment(new Date().getTime()).format("lll")
        });
       // setTotalProfit_1(0);
      }
    } catch (error) {}
  };


  const getexchangeBalance_3 = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance_3,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      if (resp.status == true) {
        setIsLoading(false);
        setexchangeBalance_3(resp);
        let balance_percentage =  (+resp.fund_balance / +resp.balance) * 100;
        //setTotalProfit_2(balance_percentage);
      }
      else
      {
        setIsLoading(false);
        setexchangeBalance_3({
          balance: 0,
          fund_balance: 0,
          date: moment(new Date().getTime()).format("lll")
        });
       // setTotalProfit_2(0);
      }
    } catch (error) {}
  };


  const getexchangeBalance_4 = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance_4,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      if (resp.status == true) {
        setIsLoading(false);
        setexchangeBalance_4(resp);
        let balance_percentage =  (+resp.fund_balance / +resp.balance) * 100;
        //setTotalProfit_3(balance_percentage);
      }
      else
      {
        setIsLoading(false);
        setexchangeBalance_4({
          balance: 0,
          fund_balance: 0,
          date: moment(new Date().getTime()).format("lll")
        });
        //setTotalProfit_3(0);
      }
    } catch (error) {}
  };

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          {isLoading == true ? (
                <>
                  <div className="loader-css">
                    <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                    />
                  </div>
                </>
              ) : (
          <div className="col-lg-10 dash_padding">
            <div className="">
              {/* {isLoading == true ? (
                <>
                  <div className="loader-css">
                    <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                    />
                  </div>
                </>
              ) : ( */}
                <main className="dash_board_main">
                  <div className="portfolio_main">
                    <h2>Portfolio</h2>
                    
                    <div className="row dash_main_paddings">
                      <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>
                          Total Profit - IP: 93
                          </h4>
                          <div className="opportunities_card_coins">
                            <h2> {isNaN(totalProfit)
                                ? "0.00"
                                : parseFloat(totalProfit).toFixed(2)} %</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>
                            Total Trades - IP: 93
                          </h4>
                          <div className="opportunities_card_coins">
                            <h2> {totalTrade}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>
                            Total Profit - IP: 101
                          </h4>
                          <div className="opportunities_card_coins">
                            <h2> {parseFloat(totalProfit_1).toFixed(2)} %</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>
                          Total Trades - IP: 101
                          </h4>
                          <div className="opportunities_card_coins">
                          <h2> {totalTrade_1}</h2>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row dash_main_padding">

                    <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>Total Profit - IP: 94</h4> 
                          <div className="opportunities_card_coins">
                          <h2>{parseFloat(totalProfit_2).toFixed(2)} %</h2>
                          {/* <h2>3.4%</h2> */}
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>
                         Total Trades - IP: 94
                          </h4>
                          <div className="opportunities_card_coins">
                            <h2>{totalTrade_2}</h2>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>
                            Total Profit - Account4
                          </h4>
                          <div className="opportunities_card_coins">
                            <h2>$ {parseFloat(totalProfit_3).toFixed(2)}</h2>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-lg-3 col-sm-6">
                        <div className="opportunities_card dash_bln">
                          <h4>Total Trades - Account4</h4>
                          <div className="opportunities_card_coins">
                            <h2>{totalTrade_3}</h2>
                          </div>
                        </div>
                      </div> */}
                     
                    </div>
                    
                    {/* <div className="row dash_main_padding">
                    <div className="col-lg-12 mt-1">
                      <div className="dashboard-char-section">
                        <div className="chart-top">
                         <span className="chart-head">Profit</span>
                         <div className="chart-calender">
                         <span
                                onClick={() => prediction("")}
                                style={{ cursor: "pointer" }}
                                className={
                                  actives == "" || undefined
                                    ? "primarychart"
                                    : "outlinbe"
                                }
                              >
                                1 D
                              </span>
                              <span
                                onClick={() => prediction("day")}
                                style={{ cursor: "pointer" }}
                                className={
                                  actives == "day" ? "primarychart" : "outlinbe"
                                }
                              >
                                1 W
                              </span>
                              <span
                                onClick={() => prediction("month")}
                                style={{ cursor: "pointer" }}
                                className={
                                  actives == "month" ? "primarychart" : "outlinbe"
                                }
                              >
                                1 M
                              </span>
                              <span
                                onClick={() => prediction("year")}
                                style={{ cursor: "pointer" }}
                                className={
                                  actives == "year" ? "primarychart" : "outlinbe"
                                }
                              >
                                1 Y
                              </span>
                         </div>
                        </div>
                        <div className="dash-chart-card">
                        <LineChart
                          width={1000}
                          height={400}
                          style={{ cursor: "poiter" }}
                          data={chartdataref.current}
                        >
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip content={<CustomTooltip />} />
                          {/* <CartesianGrid stroke="" /> */}
                          {/* <Line
                            type="monotone"
                            dataKey="date"
                            stroke="#c6ff4a"
                            activeDot={{ r: 4 }}
                          />
                          <Legend />
                          <Line
                            type="monostone"
                            dataKey="profit"
                            stroke="#c6ff4a"
                          />
                         </LineChart> */}
                        {/* </div>
                    
                      </div>
                    </div> */}
                    {/* <div className="col-log-4 mt-1">
                    <div className="flex justify-center items-center">
                    <PieChart width={200} height={200}>
                    <Pie
                        activeIndex={activeIndex}
                        data={piedataref.current}
                        innerRadius={60}
                        outerRadius={80}
                        fill="green"
                        onMouseEnter={onPieEnter}
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {piedataref.current.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
                </div>
                  </div> */}
                    {/* </div> */}

                    <div className="dash_balance_history">
                      <h2>Balance History</h2>
                      {buttonLoader == true ? (
                        <div className="loader-css">
                            <img
                                src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                alt="loader"
                                className="loader-gif"
                            />
                            </div>
                      ) :
                        exchangeBalance_1 != "" ? (
                          <div className="dash_balance_history_card">
                          <div className="history_card_content">
                            <h5>Exchange</h5>
                            <div className="binance_coin">
                              <img src={require("../images/binance_coion.png")} />
                              <p>Binance IP: 93</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Spot Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_1.balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Funding Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_1.fund_balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Date & Time</h5>
                            <div className="binance_coin">
                              <p>{exchangeBalance_1.date}</p>
                            </div>
                          </div>
                        </div>
                        ): ("")}
                        {exchangeBalance_2 != "" ? (
                          <div className="dash_balance_history_card">
                          <div className="history_card_content">
                            <h5>Exchange</h5>
                            <div className="binance_coin">
                              <img src={require("../images/binance_coion.png")} />
                              <p>Binance IP: 101</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Spot Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_2.balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Funding Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_2.fund_balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Date & Time</h5>
                            <div className="binance_coin">
                              <p>{exchangeBalance_2.date}</p>
                            </div>
                          </div>
                        </div>
                        ) : ("")}
                         {exchangeBalance_3 != "" ? (
                          <div className="dash_balance_history_card">
                          <div className="history_card_content">
                            <h5>Exchange</h5>
                            <div className="binance_coin">
                              <img src={require("../images/binance_coion.png")} />
                              <p>Binance IP: 94</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Spot Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_3.balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Funding Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_3.fund_balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Date & Time</h5>
                            <div className="binance_coin">
                              <p>{exchangeBalance_3.date}</p>
                            </div>
                          </div>
                        </div>
                        ) : ("")}

                       {/* {exchangeBalance_4 != "" ? (
                          <div className="dash_balance_history_card">
                          <div className="history_card_content">
                            <h5>Exchange Account4</h5>
                            <div className="binance_coin">
                              <img src={require("../images/binance_coion.png")} />
                              <p>Binance Account4</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Spot Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_4.balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Funding Balance</h5>
                            <div className="binance_coin">
                              <p>{parseFloat(exchangeBalance_4.fund_balance).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="history_card_content">
                            <h5>Date & Time</h5>
                            <div className="binance_coin">
                              <p>{exchangeBalance_4.date}</p>
                            </div>
                          </div>
                        </div>
                        ) : ("")} */}
                    
                      {/* <div className="dash_balance_history_card">
                        <div className="history_card_content">
                          <h5>Exchange</h5>
                          <div className="binance_coin">
                            <img src={require("../images/daul_coin.png")} />
                            <p>Binance</p>
                          </div>
                        </div>
                        <div className="history_card_content">
                          <h5>Spot Balance</h5>
                          <div className="binance_coin">
                            <p>1000</p>
                          </div>
                        </div>
                        <div className="history_card_content">
                          <h5>Invested Amount</h5>
                          <div className="binance_coin">
                            <p>100</p>
                          </div>
                        </div>
                        <div className="history_card_content">
                          <h5>Exchange</h5>
                          <div className="binance_coin">
                            <p>200.00</p>
                          </div>
                        </div>
                      </div>
                      <div className="dash_balance_history_card">
                        <div className="history_card_content">
                          <h5>Exchange</h5>
                          <div className="binance_coin">
                            <img src={require("../images/binance_coion.png")} />
                            <p>Binance</p>
                          </div>
                        </div>
                        <div className="history_card_content">
                          <h5>Spot Balance</h5>
                          <div className="binance_coin">
                            <p>1000</p>
                          </div>
                        </div>
                        <div className="history_card_content">
                          <h5>Invested Amount</h5>
                          <div className="binance_coin">
                            <p>100</p>
                          </div>
                        </div>
                        <div className="history_card_content">
                          <h5>Exchange</h5>
                          <div className="binance_coin">
                            <p>200.00</p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </main>
              {/* // )} */}
            </div>
          </div>
              )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;