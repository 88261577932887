import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Market from './pages/Market';
import Orders from './pages/Orders';
import Profit from './pages/Profit';
import Register from './pages/Register';
import semantic from 'semantic-ui-css'
import ConnectExchange from './pages/ConnectExchange';
import Login from './pages/Login';
import Opportunities from './pages/Opportunities';
import Deposit from './pages/Deposit';
import LoginHistory from './pages/LoginHistory';
import TradeHistory from './pages/TradeHistory';
import Subscription from './pages/Subscription';
import TradeOpportunity from './pages/TradeOpportunity';
import Forget from './pages/Forget';
import InvestmentSettings from './pages/InvestmentSettings';

import ProfitLog_triangular from './pages/Profit_triangular';

import Profit1 from './pages/Profit1';
import Profit2 from './pages/Profit2';
import Profit3 from './pages/Profit3';

import Pairupdate from './pages/Pairupdate';

import {ToastContainer, toast} from "react-toastify";
import { removeAuthToken } from './core/lib/localStorage';
import "react-toastify/dist/ReactToastify.css";
import './App.css';

function App() {

  
  function RequireAuth({children}) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
   <>
   {/* <BrowserRouter basename='/testing'> */}
    <BrowserRouter>
    <ToastContainer />
     <Routes>
      <Route path="/" element={<Login/>} />
      <Route path='/signup' element={<Register/>} />
      <Route path='/dashboard' element={
        <RequireAuth>
          <Dashboard/>
        </RequireAuth>} />
      <Route path="/market" element={
        <RequireAuth>
          <Market/>
        </RequireAuth>
      } />
      <Route path='/orders' element={<Orders/>} />
      {/* <Route path='/profit' element={<Profit/>} /> */}
      {/* <Route path='/connectexchange' element={<ConnectExchange/>} /> */}
      <Route path='/bot-settings' element={<Opportunities/>} />
      {/* <Route path='/deposit' element={<Deposit/>}/> */}
      <Route path='/loginhistory' element={<LoginHistory/>}/>
      {/* <Route path='/tradehistory' element={<TradeHistory/>}/> */}
      {/* <Route path='/opportunities' element={<TradeOpportunity/>}/> */}
      <Route path="/investment-settings" element={<InvestmentSettings/>}/>
      {/* <Route path='/subscription' element={<Subscription/>} /> */}
      <Route path='/forget' element={<Forget/>} />
      <Route path="/profit/account1" element={<Profit/>} />
      <Route path="/profit/account2" element={<Profit1/>} />
      <Route path="/profit/account3" element={<Profit2/>} />
      <Route path="/profit/account4" element={<Profit3/>} />
      <Route path="/pair_management" element={<Pairupdate/>} />
     </Routes>
     </BrowserRouter>
   </>
  );
}

export default App;


