import React, { useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

function SidebarNew() {
  const theme = useTheme();
  // const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [dropstatus, setdropstatus] = useState(false);
  const [settingstatus, setsettingstatus] = useState(false);

  const dropdowns = async () => {
    console.log(dropstatus,"dropstatus")
    if(dropstatus == true){
      setdropstatus(false);
    }else{
      setdropstatus(true);
    }
  };

  const settings_dropdowns = async () => {
    console.log(settingstatus,"settingstatus")
    if(settingstatus == true){
      setsettingstatus(false);
    }else{
      setsettingstatus(true);
    }
  };


  return (
    <>
      <aside className="asidemeni">
        <div className="mennu_sidemain">
          <div className="mennu_side">
            <NavLink to="/dashboard" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="Dashboard"><path fill="#7c8395" d="M9.9 3H4.6C3.7 3 3 3.7 3 4.6v8.5c0 .9.7 1.6 1.6 1.6h5.3c.9 0 1.6-.7 1.6-1.6V4.6C11.5 3.7 10.8 3 9.9 3zM9.4 12.5H5.1V5.1h4.2V12.5zM9.9 15.7H4.6c-.9 0-1.6.7-1.6 1.6v2.1C3 20.3 3.7 21 4.6 21h5.3c.9 0 1.6-.7 1.6-1.6v-2.1C11.5 16.4 10.8 15.7 9.9 15.7zM9.4 18.9H5.1v-1.1h4.2V18.9zM19.4 9.4h-5.3c-.9 0-1.6.7-1.6 1.6v8.5c0 .9.7 1.6 1.6 1.6h5.3c.9 0 1.6-.7 1.6-1.6v-8.5C21 10.1 20.3 9.4 19.4 9.4zM18.9 18.9h-4.2v-7.4h4.2V18.9zM19.4 3h-5.3c-.9 0-1.6.7-1.6 1.6v2.1c0 .9.7 1.6 1.6 1.6h5.3c.9 0 1.6-.7 1.6-1.6V4.6C21 3.7 20.3 3 19.4 3zM18.9 6.2h-4.2V5.1h4.2V6.2z" class="color3b65f5 svgShape"></path></svg>
                </div>
                <span className="side-name">Dashboard</span>
              </div>
            </NavLink>
            {/* <NavLink to="/market" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-side"
                    viewBox="0 0 101 101"
                    id="Graph"
                  >
                    <path
                      d="M82.2 79.8h-61v-61c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v63.4c0 1.3 1.1 2.4 2.4 2.4h63.4c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                    <path
                      d="M44.5 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.5zM32.9 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4 1.3-.1 2.4-1.1 2.4-2.5zM67.8 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4 1.3-.1 2.4-1.1 2.4-2.5zM79.4 70.5V53.2c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v17.3c0 1.3 1.1 2.4 2.4 2.4s2.4-1 2.4-2.4zM56.1 70.5V53.2c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v17.3c0 1.3 1.1 2.4 2.4 2.4s2.4-1 2.4-2.4z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                  </svg>{" "}
                </div>
                <span className="side-name">Market</span>
              </div>
            </NavLink>
            <NavLink to="/orders" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-side"
                    viewBox="0 0 35 35"
                    id="Order"
                  >
                    <path
                      d="M27.44,32.75H7.57a5.32,5.32,0,0,1-5.32-5.32V7.56A5.32,5.32,0,0,1,7.57,2.25H27.44a5.31,5.31,0,0,1,5.31,5.31V27.43A5.32,5.32,0,0,1,27.44,32.75ZM7.57,4.75A2.81,2.81,0,0,0,4.75,7.56V27.43a2.82,2.82,0,0,0,2.82,2.82H27.44a2.81,2.81,0,0,0,2.81-2.82V7.56a2.81,2.81,0,0,0-2.81-2.81Z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                    <path
                      d="M13.52 18.75a2.42 2.42 0 0 1-1-.22 2.49 2.49 0 0 1-1.46-2.29V3.5a1.25 1.25 0 0 1 2.5 0V16.24l2.35-2.08a2.41 2.41 0 0 1 3.21 0l2.41 2.09L21.45 3.5a1.25 1.25 0 0 1 2.5 0V16.24a2.49 2.49 0 0 1-1.46 2.29 2.41 2.41 0 0 1-2.61-.39L17.5 16.08l-2.38 2.07A2.41 2.41 0 0 1 13.52 18.75zm3.94-2.7zm.09 0zM19 26.57h-9a1.25 1.25 0 1 1 0-2.5h9a1.25 1.25 0 0 1 0 2.5z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                  </svg>
                </div>
                <span className="side-name">Orders</span>
              </div>
            </NavLink> */}
            <NavLink to="/profit/account1" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 16 16" id="Trending"><path fill="#7c8395" d="M8.75,4 L13.2981852,4.0014421 L13.2981852,4.0014421 L13.4057674,4.01593414 L13.4057674,4.01593414 L13.5189807,4.04910951 L13.5189807,4.04910951 L13.6205886,4.09687025 L13.6205886,4.09687025 L13.6591656,4.12034634 L13.6591656,4.12034634 L13.7079018,4.15478674 L13.7839626,4.22178402 L13.7839626,4.22178402 L13.868248,4.32297611 L13.868248,4.32297611 L13.9265675,4.42269815 L13.9265675,4.42269815 L13.9681121,4.52811787 L13.9681121,4.52811787 L13.9911767,4.62547539 L13.9911767,4.62547539 L14.0015268,4.75 L14.0015268,9.25389045 C14.0015268,9.66810401 13.6657404,10.0038905 13.2515268,10.0038905 C12.871831,10.0038905 12.5580358,9.72173657 12.5083734,9.35566101 L12.5015268,9.25389045 L12.501,6.561 L8.28044397,10.7841066 C8.01406767,11.0505974 7.59709422,11.074734 7.30343733,10.8565792 L7.21931368,10.7838641 L5.74855033,9.31112282 L3.28043609,11.7802241 C2.98760143,12.0731758 2.51272771,12.0732708 2.21977594,11.7804361 C1.95345616,11.5142228 1.92916681,11.0975639 2.14696257,10.8039089 L2.21956391,10.7197759 L5.21836468,7.71977594 C5.48474214,7.45329196 5.90171068,7.42915884 6.19536435,7.64731194 L6.2794871,7.72002639 L7.75024256,9.19275974 L11.441,5.5 L8.75,5.5 C8.37030423,5.5 8.05650904,5.21784612 8.00684662,4.85177056 L8,4.75 C8,4.33578644 8.33578644,4 8.75,4 Z" class="color212121 svgShape"></path></svg>
                </div>
                <span className="side-name">Profit - IP: 93</span>
              </div>
            </NavLink>

            <NavLink to="/profit/account2" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 16 16" id="Trending"><path fill="#7c8395" d="M8.75,4 L13.2981852,4.0014421 L13.2981852,4.0014421 L13.4057674,4.01593414 L13.4057674,4.01593414 L13.5189807,4.04910951 L13.5189807,4.04910951 L13.6205886,4.09687025 L13.6205886,4.09687025 L13.6591656,4.12034634 L13.6591656,4.12034634 L13.7079018,4.15478674 L13.7839626,4.22178402 L13.7839626,4.22178402 L13.868248,4.32297611 L13.868248,4.32297611 L13.9265675,4.42269815 L13.9265675,4.42269815 L13.9681121,4.52811787 L13.9681121,4.52811787 L13.9911767,4.62547539 L13.9911767,4.62547539 L14.0015268,4.75 L14.0015268,9.25389045 C14.0015268,9.66810401 13.6657404,10.0038905 13.2515268,10.0038905 C12.871831,10.0038905 12.5580358,9.72173657 12.5083734,9.35566101 L12.5015268,9.25389045 L12.501,6.561 L8.28044397,10.7841066 C8.01406767,11.0505974 7.59709422,11.074734 7.30343733,10.8565792 L7.21931368,10.7838641 L5.74855033,9.31112282 L3.28043609,11.7802241 C2.98760143,12.0731758 2.51272771,12.0732708 2.21977594,11.7804361 C1.95345616,11.5142228 1.92916681,11.0975639 2.14696257,10.8039089 L2.21956391,10.7197759 L5.21836468,7.71977594 C5.48474214,7.45329196 5.90171068,7.42915884 6.19536435,7.64731194 L6.2794871,7.72002639 L7.75024256,9.19275974 L11.441,5.5 L8.75,5.5 C8.37030423,5.5 8.05650904,5.21784612 8.00684662,4.85177056 L8,4.75 C8,4.33578644 8.33578644,4 8.75,4 Z" class="color212121 svgShape"></path></svg>
                </div>
                <span className="side-name">Profit - IP: 101</span>
              </div>
            </NavLink>


            <NavLink to="/profit/account3" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 16 16" id="Trending"><path fill="#7c8395" d="M8.75,4 L13.2981852,4.0014421 L13.2981852,4.0014421 L13.4057674,4.01593414 L13.4057674,4.01593414 L13.5189807,4.04910951 L13.5189807,4.04910951 L13.6205886,4.09687025 L13.6205886,4.09687025 L13.6591656,4.12034634 L13.6591656,4.12034634 L13.7079018,4.15478674 L13.7839626,4.22178402 L13.7839626,4.22178402 L13.868248,4.32297611 L13.868248,4.32297611 L13.9265675,4.42269815 L13.9265675,4.42269815 L13.9681121,4.52811787 L13.9681121,4.52811787 L13.9911767,4.62547539 L13.9911767,4.62547539 L14.0015268,4.75 L14.0015268,9.25389045 C14.0015268,9.66810401 13.6657404,10.0038905 13.2515268,10.0038905 C12.871831,10.0038905 12.5580358,9.72173657 12.5083734,9.35566101 L12.5015268,9.25389045 L12.501,6.561 L8.28044397,10.7841066 C8.01406767,11.0505974 7.59709422,11.074734 7.30343733,10.8565792 L7.21931368,10.7838641 L5.74855033,9.31112282 L3.28043609,11.7802241 C2.98760143,12.0731758 2.51272771,12.0732708 2.21977594,11.7804361 C1.95345616,11.5142228 1.92916681,11.0975639 2.14696257,10.8039089 L2.21956391,10.7197759 L5.21836468,7.71977594 C5.48474214,7.45329196 5.90171068,7.42915884 6.19536435,7.64731194 L6.2794871,7.72002639 L7.75024256,9.19275974 L11.441,5.5 L8.75,5.5 C8.37030423,5.5 8.05650904,5.21784612 8.00684662,4.85177056 L8,4.75 C8,4.33578644 8.33578644,4 8.75,4 Z" class="color212121 svgShape"></path></svg>
                </div>
                <span className="side-name">Profit - IP: 94</span>
              </div>
            </NavLink>

            {/* <NavLink to="/profit/account4" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 16 16" id="Trending"><path fill="#7c8395" d="M8.75,4 L13.2981852,4.0014421 L13.2981852,4.0014421 L13.4057674,4.01593414 L13.4057674,4.01593414 L13.5189807,4.04910951 L13.5189807,4.04910951 L13.6205886,4.09687025 L13.6205886,4.09687025 L13.6591656,4.12034634 L13.6591656,4.12034634 L13.7079018,4.15478674 L13.7839626,4.22178402 L13.7839626,4.22178402 L13.868248,4.32297611 L13.868248,4.32297611 L13.9265675,4.42269815 L13.9265675,4.42269815 L13.9681121,4.52811787 L13.9681121,4.52811787 L13.9911767,4.62547539 L13.9911767,4.62547539 L14.0015268,4.75 L14.0015268,9.25389045 C14.0015268,9.66810401 13.6657404,10.0038905 13.2515268,10.0038905 C12.871831,10.0038905 12.5580358,9.72173657 12.5083734,9.35566101 L12.5015268,9.25389045 L12.501,6.561 L8.28044397,10.7841066 C8.01406767,11.0505974 7.59709422,11.074734 7.30343733,10.8565792 L7.21931368,10.7838641 L5.74855033,9.31112282 L3.28043609,11.7802241 C2.98760143,12.0731758 2.51272771,12.0732708 2.21977594,11.7804361 C1.95345616,11.5142228 1.92916681,11.0975639 2.14696257,10.8039089 L2.21956391,10.7197759 L5.21836468,7.71977594 C5.48474214,7.45329196 5.90171068,7.42915884 6.19536435,7.64731194 L6.2794871,7.72002639 L7.75024256,9.19275974 L11.441,5.5 L8.75,5.5 C8.37030423,5.5 8.05650904,5.21784612 8.00684662,4.85177056 L8,4.75 C8,4.33578644 8.33578644,4 8.75,4 Z" class="color212121 svgShape"></path></svg>
                </div>
                <span className="side-name">Profit Account4</span>
              </div>
            </NavLink> */}
            {/* <NavLink to="/connectexchange" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="Swap"><path d="M14 2.256V30c-2.209 0-4-1.791-4-4V13H4.714c-.633 0-.949-.765-.502-1.212l9.607-9.607C13.886 2.114 14 2.162 14 2.256zM27.788 20.212l-9.6 9.6C18.118 29.882 18 29.832 18 29.734V2c2.209 0 4 1.791 4 4v13h5.286C27.918 19 28.235 19.765 27.788 20.212z" fill="#7c8395" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Exchange</span>
              </div>
            </NavLink>
            <NavLink to="/opportunities" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-side"
                    viewBox="0 0 46.21 46.79"
                    id="Opportunities"
                  >
                    <path
                      fill="#595b5e"
                      d="M20.34 31.86c-.15.21-.29.44-.44.66a22.22 22.22 0 0 0-2.72 6.08.34.34 0 0 1-.62.08 15.28 15.28 0 0 0-8.48-6.79.33.33 0 0 0-.44.32v1.06a.34.34 0 0 1-.54.27l-3.22-2.38L.14 28.4a.34.34 0 0 1 0-.55l5-3.71 2-1.44a.34.34 0 0 1 .54.27v1.23a.33.33 0 0 0 .26.33 22.29 22.29 0 0 1 12.4 7.33zm25.73-3.73a.34.34 0 0 1 0 .54l-3.74 2.76-3.23 2.39a.34.34 0 0 1-.54-.27v-1.09a.34.34 0 0 0-.44-.32 15.29 15.29 0 0 0-10.69 14.59v.06h-7.09v-.06a22.41 22.41 0 0 1 18-22 .32.32 0 0 0 .27-.32v-1.22a.34.34 0 0 1 .54-.27l1.94 1.44zM28.37 7.58h-1.24a.34.34 0 0 0-.35.32v16a.31.31 0 0 1-.15.25 23.28 23.28 0 0 0-3.18 2.58.35.35 0 0 1-.5 0 22.69 22.69 0 0 0-3.37-3 .31.31 0 0 1-.13-.25V7.9a.34.34 0 0 0-.35-.32h-1.4a.33.33 0 0 1-.28-.52l2-2.54L22.87.18l.15-.18.15.18 3.6 4.53 1.88 2.35a.33.33 0 0 1-.28.52z"
                      class="color231f20 svgShape"
                    ></path>
                  </svg>
                </div>
                <span className="side-name">Opportunities</span>
              </div>
            </NavLink> */}
            {/* <NavLink to="/setting" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="settings"><g fill="#7c8395" class="color000000 svgShape"><g fill="#7c8395" class="color000000 svgShape"><path d="M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19 1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5 1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19 1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5 1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22zm-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14 2.36 2.36 0 0 1 2.59.47l.54.54.88-.88-.54-.55a2.34 2.34 0 0 1-.48-2.56 2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46 2.36 2.36 0 0 1 .47-2.59l.54-.54-.88-.88-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14 2.36 2.36 0 0 1-2.59-.47l-.54-.54-.88.88.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46 2.36 2.36 0 0 1-.47 2.59l-.54.54.88.88.55-.54a2.39 2.39 0 0 1 4 1.67z" fill="#7c8395" class="color000000 svgShape"></path><path d="M12 15.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z" fill="#7c8395" class="color000000 svgShape"></path></g></g></svg>
                </div>
                <span className="side-name">Settings</span>
              </div>
            </NavLink> */}

             <NavLink to="/pair_management" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="settings"><g fill="#7c8395" class="color000000 svgShape"><g fill="#7c8395" class="color000000 svgShape"><path d="M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19 1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5 1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19 1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5 1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22zm-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14 2.36 2.36 0 0 1 2.59.47l.54.54.88-.88-.54-.55a2.34 2.34 0 0 1-.48-2.56 2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46 2.36 2.36 0 0 1 .47-2.59l.54-.54-.88-.88-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14 2.36 2.36 0 0 1-2.59-.47l-.54-.54-.88.88.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46 2.36 2.36 0 0 1-.47 2.59l-.54.54.88.88.55-.54a2.39 2.39 0 0 1 4 1.67z" fill="#7c8395" class="color000000 svgShape"></path><path d="M12 15.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z" fill="#7c8395" class="color000000 svgShape"></path></g></g></svg>
                </div>
                <span className="side-name">Pair Settings</span>
              </div>
            </NavLink>
            {/* <NavLink to="/" className="navlink_new"> */}
            <div className="chat-optionside-history-main">
              <div className="chat-optionside-history" onClick={settings_dropdowns}>
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="settings"><g fill="#7c8395" class="color000000 svgShape"><g fill="#7c8395" class="color000000 svgShape"><path d="M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19 1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5 1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19 1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5 1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22zm-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14 2.36 2.36 0 0 1 2.59.47l.54.54.88-.88-.54-.55a2.34 2.34 0 0 1-.48-2.56 2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46 2.36 2.36 0 0 1 .47-2.59l.54-.54-.88-.88-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14 2.36 2.36 0 0 1-2.59-.47l-.54-.54-.88.88.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46 2.36 2.36 0 0 1-.47 2.59l-.54.54.88.88.55-.54a2.39 2.39 0 0 1 4 1.67z" fill="#7c8395" class="color000000 svgShape"></path><path d="M12 15.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z" fill="#7c8395" class="color000000 svgShape"></path></g></g></svg>
                </div>
                <div className="history_rgh_min">
                <span className="side-name">Settings</span>
                {settingstatus == true ? 
                <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="DownArrow"><path d="M22.782 13.8 17 19.582 11.218 13.8a1 1 0 0 0-1.414 1.414L16.29 21.7a.992.992 0 0 0 .71.292.997.997 0 0 0 .71-.292l6.486-6.486a1 1 0 0 0-1.414-1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                 : 
                <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                 }
                </div>
              </div>
              <div className={settingstatus == true ? "dropdown-content" : "dropdown-content d-none"}>
               <NavLink to="/bot-settings">
                <div className="history_drop_min">
                  <span className="dash-coin-name">Bot</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                </NavLink> 
                <NavLink to="/investment-settings">
                <div className="history_drop_min">
                  <span className="dash-coin-name">Investment</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                </NavLink> 
               </div>
               </div>

               <NavLink to="/loginhistory" className="navlink_new">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 512 512" id="AlarmClock"><path d="m470 124.837-98.443-81.78-27.814 32.931 98.442 81.769L470 124.837zM167.192 74.919 139.366 42 42 124.837l27.814 32.919 97.378-82.837zm99.509 97.709H234.6v127.446l101.649 60.539L352.3 334.06l-85.6-49.917V172.628zM256 87.665c-107 0-192.601 86.021-192.601 191.166C63.399 383.98 149 470 256 470c105.936 0 192.601-86.02 192.601-191.169 0-105.144-86.665-191.166-192.601-191.166zm0 339.855c-82.393 0-149.8-66.906-149.8-148.688 0-81.777 67.407-148.684 149.8-148.684 82.394 0 149.8 66.906 149.8 148.684 0 82.839-67.406 148.688-149.8 148.688z" fill="#7c8395" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Login History</span>
              </div>
            </NavLink>
             
            {/* </NavLink> */}
          </div>

          <div className="pos-abs">
            {/* <NavLink to="/subscription" className="navlink_new">
              <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#595b5e" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Subscription</span>
                </div>
                </NavLink> */}
            {/* <div className="side-lo-div" onClick={logout}>
              <div className="chat-optionside">
                <div className="menu_items_fex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-side"
                    viewBox="0 0 24 24"
                    id="logout"
                  >
                    <path
                      d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                    <path
                      d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                  </svg>
                </div>
                <span className="side-name">Logout</span>
              </div>
            </div> */}
          </div>
        </div>
      </aside>
    </>
  );
}

export default SidebarNew;
