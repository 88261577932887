import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import {
  getMethod,
  postMethod,
} from "../core/sevice/common.api";
import apiService from "../core/sevice/detail";
import ReactSwitch from "react-switch";

function Pairupdate() {
  const initialFormValue = {
    pair: "",
    primary: "",
    secondary: "",
    // status: "Active",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationErr, setValidationErr] = useState("");
  const [pairs, setPairs] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const { pair, primary, secondary, status } = formValue;

  useEffect(() => {
    fetchPairs();
    const options = [
      { value: true, label: "Active" },
      { value: false, label: "Deactive" },
    ];
    setStatusOptions(options);
    setSelectedStatus(options[0]);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleStatusChange = (selectedOption) => {
    setFormValue({ ...formValue, status: selectedOption.value });
    setSelectedStatus(selectedOption);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.pair) errors.pair = "Pair Name is required!";
    if (!values.primary) errors.primary = "Base Symbol is required!";
    if (!values.secondary) errors.secondary = "Quote Symbol is required!";
    setValidationErr(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchPairs = async () => {
    try {
      setIsLoading(true);
      const data = { apiUrl: apiService.getPairs };
      const response = await getMethod(data);
      if (response.status) {
        setPairs(response.data);
        setIsLoading(false);
      } else {
        toast.error(response.Message);
      }
    } catch (error) {
      console.error("Error fetching pairs:", error);
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    if (!validate(formValue)) return;

    const payload = formValue;
    const data = {
      apiUrl: editingIndex !== null ? apiService.updatePair : apiService.addPair,
      payload,
    };
    setButtonLoader(true);

    try {
      const response = await postMethod(data);
      if (response.success) {
        toast.success(response.message);
        fetchPairs();
        resetForm();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setButtonLoader(false);
    }
  };

  const resetForm = () => {
    setFormValue(initialFormValue);
    setEditingIndex(null);
    setSelectedStatus(statusOptions[0]);
  };

  const handleEdit = (index) => {
    const pair = pairs[index];
    setFormValue(pair);
    setSelectedStatus(statusOptions.find((opt) => opt.value === pair.status));
    setEditingIndex(index);
  };

  const handleDelete = async (index) => {
    try {
      const pair = pairs[index];
      const data = {
        apiUrl: apiService.deletePair,
        payload: { id: pair._id },
      };
      const response = await postMethod(data);
      if (response.success) {
        toast.success(response.message);
        fetchPairs();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting pair:", error);
    }
  };


  const toggleStatus = async (index) => {
    try {
      const pair = pairs[index];
      const newStatus = pair.status === true ? false : true;
      const data = {
        apiUrl: apiService.updatePairStatus,
        payload: { id: pair._id, status: newStatus },
      };
      const response = await postMethod(data);
      if (response.status) {
        const updatedPairs = [...pairs];
        updatedPairs[index].status = newStatus;
        setPairs(updatedPairs);
        toast.success("Status updated successfully!");
      } else {
        toast.error(response.Message);
      }
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-sm-9">
            <div className="container-fluid settings-container">
              {isLoading ? (
                 <div className="loader-css">
                 <img
                   src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                   alt="loader"
                   className="loader-gif"
                 />
               </div>
              ) : (
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-lg-6">
                    <h3 style={{color:"white"}}>Pair Manager</h3>
                    <div className="setting_blw_col2">
                      <div className="input-settings">
                        <label>Pair Name</label>
                        <input
                          type="text"
                          name="pair"
                          value={pair}
                          onChange={handleInputChange}
                          className="login-input mt-1"
                        />
                        {validationErr.pair && (
                          <p className="errorcss">{validationErr.pair}</p>
                        )}
                      </div>

                      <div className="input-settings">
                        <label>Base Symbol</label>
                        <input
                          type="text"
                          name="primary"
                          value={primary}
                          onChange={handleInputChange}
                          className="login-input mt-1"
                        />
                        {validationErr.primary && (
                          <p className="errorcss">{validationErr.primary}</p>
                        )}
                      </div>

                      <div className="input-settings">
                        <label>Quote Symbol</label>
                        <input
                          type="text"
                          name="secondary"
                          value={secondary}
                          onChange={handleInputChange}
                          className="login-input mt-1"
                        />
                        {validationErr.secondary && (
                          <p className="errorcss">{validationErr.secondary}</p>
                        )}
                      </div>

                      {/* <div className="input-settings">
                        <label>Status</label>
                        <Select
                          options={statusOptions}
                          value={selectedStatus}
                          onChange={handleStatusChange}
                          className="form-control"
                        />
                      </div> */}

                      <div>
                        {buttonLoader ? (
                          <button className="login-submit-btn mt-4">
                            Loading...
                          </button>
                        ) : (
                          <button
                            className="login-submit-btn mt-4"
                            onClick={handleFormSubmit}
                          >
                            {editingIndex !== null ? "Update" : "Submit"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                  <div class="table-responsive">
                  <table className='market-table mt-5'>
                    <thead className='market-head'>
                        <tr>
                          <th>S.No</th>
                          <th>Pair Name</th>
                          {/* <th>Base Symbol</th>
                          <th>Quote Symbol</th> */}
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        pairs.length > 0 ? 
                        pairs.map((pair, index) => (
                          <tr key={pair.id || index}>
                            <td>
                            <div className='order-place-frst'>
                            <span className='order-place-inner'>{index + 1} </span> </div></td>
                            <td> <div className='order-place-frst'>
                            <span className='order-place-inner'>{pair.pair} </span> </div></td>
                            {/* <td> <div className='order-place-frst'>
                            <span className='order-place-inner'>{pair.primary} </span> </div></td>
                            <td> <div className='order-place-frst'>
                            <span className='order-place-inner'>{pair.secondary} </span> </div></td> */}
                            {/* <td> <div className='order-place-frst'>
                            <span className='order-place-inner'>{pair.status === true ? "Active" : "Deactive"} </span> </div></td> */}
                            <td>
                              <ReactSwitch
                                checked={pair.status === true}
                                onChange={() => toggleStatus(index)}
                                checkedIcon={<div className="switch-label"
                                    style={{
                                        color: "white",
                                        fontSize: 12,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}>On</div>}
                                uncheckedIcon={<div className="switch-label"
                                style={{
                                    color: "black",
                                    fontSize: 12,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    }}
                                >Off</div>}
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleEdit(index)}
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn-danger btn-sm ml-2"
                                onClick={() => handleDelete(index)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        )) : 
                        <tr>
                            <td colSpan={6}>
                            <div className='order-place-frst'>
                            <span className='order-place-inner'> No Pairs Found
                            </span> </div>
                            </td>
                        </tr>
                        }
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pairupdate;
