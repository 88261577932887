import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link,useNavigate } from "react-router-dom";

const drawerWidth = 240;

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [loginStatus,setLoginStatus] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
}

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#121418", color: "#fff" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <div className="landing-header1">
          <img
            src={require("../images/daulah_holding_logo.png")}
            alt="logo"
            className="logo-img"
          />
        </div>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/dashboard">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="Dashboard"><path fill="#7c8395" d="M9.9 3H4.6C3.7 3 3 3.7 3 4.6v8.5c0 .9.7 1.6 1.6 1.6h5.3c.9 0 1.6-.7 1.6-1.6V4.6C11.5 3.7 10.8 3 9.9 3zM9.4 12.5H5.1V5.1h4.2V12.5zM9.9 15.7H4.6c-.9 0-1.6.7-1.6 1.6v2.1C3 20.3 3.7 21 4.6 21h5.3c.9 0 1.6-.7 1.6-1.6v-2.1C11.5 16.4 10.8 15.7 9.9 15.7zM9.4 18.9H5.1v-1.1h4.2V18.9zM19.4 9.4h-5.3c-.9 0-1.6.7-1.6 1.6v8.5c0 .9.7 1.6 1.6 1.6h5.3c.9 0 1.6-.7 1.6-1.6v-8.5C21 10.1 20.3 9.4 19.4 9.4zM18.9 18.9h-4.2v-7.4h4.2V18.9zM19.4 3h-5.3c-.9 0-1.6.7-1.6 1.6v2.1c0 .9.7 1.6 1.6 1.6h5.3c.9 0 1.6-.7 1.6-1.6V4.6C21 3.7 20.3 3 19.4 3zM18.9 6.2h-4.2V5.1h4.2V6.2z" class="color3b65f5 svgShape"></path></svg>
                </div>
                <span className="side-name">Dashboard</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/profit/account1">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 16 16" id="Trending"><path fill="#7c8395" d="M8.75,4 L13.2981852,4.0014421 L13.2981852,4.0014421 L13.4057674,4.01593414 L13.4057674,4.01593414 L13.5189807,4.04910951 L13.5189807,4.04910951 L13.6205886,4.09687025 L13.6205886,4.09687025 L13.6591656,4.12034634 L13.6591656,4.12034634 L13.7079018,4.15478674 L13.7839626,4.22178402 L13.7839626,4.22178402 L13.868248,4.32297611 L13.868248,4.32297611 L13.9265675,4.42269815 L13.9265675,4.42269815 L13.9681121,4.52811787 L13.9681121,4.52811787 L13.9911767,4.62547539 L13.9911767,4.62547539 L14.0015268,4.75 L14.0015268,9.25389045 C14.0015268,9.66810401 13.6657404,10.0038905 13.2515268,10.0038905 C12.871831,10.0038905 12.5580358,9.72173657 12.5083734,9.35566101 L12.5015268,9.25389045 L12.501,6.561 L8.28044397,10.7841066 C8.01406767,11.0505974 7.59709422,11.074734 7.30343733,10.8565792 L7.21931368,10.7838641 L5.74855033,9.31112282 L3.28043609,11.7802241 C2.98760143,12.0731758 2.51272771,12.0732708 2.21977594,11.7804361 C1.95345616,11.5142228 1.92916681,11.0975639 2.14696257,10.8039089 L2.21956391,10.7197759 L5.21836468,7.71977594 C5.48474214,7.45329196 5.90171068,7.42915884 6.19536435,7.64731194 L6.2794871,7.72002639 L7.75024256,9.19275974 L11.441,5.5 L8.75,5.5 C8.37030423,5.5 8.05650904,5.21784612 8.00684662,4.85177056 L8,4.75 C8,4.33578644 8.33578644,4 8.75,4 Z" class="color212121 svgShape"></path></svg>
                </div>
                <span className="side-name">Profit Account1</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/profit/account2">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 16 16" id="Trending"><path fill="#7c8395" d="M8.75,4 L13.2981852,4.0014421 L13.2981852,4.0014421 L13.4057674,4.01593414 L13.4057674,4.01593414 L13.5189807,4.04910951 L13.5189807,4.04910951 L13.6205886,4.09687025 L13.6205886,4.09687025 L13.6591656,4.12034634 L13.6591656,4.12034634 L13.7079018,4.15478674 L13.7839626,4.22178402 L13.7839626,4.22178402 L13.868248,4.32297611 L13.868248,4.32297611 L13.9265675,4.42269815 L13.9265675,4.42269815 L13.9681121,4.52811787 L13.9681121,4.52811787 L13.9911767,4.62547539 L13.9911767,4.62547539 L14.0015268,4.75 L14.0015268,9.25389045 C14.0015268,9.66810401 13.6657404,10.0038905 13.2515268,10.0038905 C12.871831,10.0038905 12.5580358,9.72173657 12.5083734,9.35566101 L12.5015268,9.25389045 L12.501,6.561 L8.28044397,10.7841066 C8.01406767,11.0505974 7.59709422,11.074734 7.30343733,10.8565792 L7.21931368,10.7838641 L5.74855033,9.31112282 L3.28043609,11.7802241 C2.98760143,12.0731758 2.51272771,12.0732708 2.21977594,11.7804361 C1.95345616,11.5142228 1.92916681,11.0975639 2.14696257,10.8039089 L2.21956391,10.7197759 L5.21836468,7.71977594 C5.48474214,7.45329196 5.90171068,7.42915884 6.19536435,7.64731194 L6.2794871,7.72002639 L7.75024256,9.19275974 L11.441,5.5 L8.75,5.5 C8.37030423,5.5 8.05650904,5.21784612 8.00684662,4.85177056 L8,4.75 C8,4.33578644 8.33578644,4 8.75,4 Z" class="color212121 svgShape"></path></svg>
                </div>
                <span className="side-name">Profit Account2</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/profit/account3">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 16 16" id="Trending"><path fill="#7c8395" d="M8.75,4 L13.2981852,4.0014421 L13.2981852,4.0014421 L13.4057674,4.01593414 L13.4057674,4.01593414 L13.5189807,4.04910951 L13.5189807,4.04910951 L13.6205886,4.09687025 L13.6205886,4.09687025 L13.6591656,4.12034634 L13.6591656,4.12034634 L13.7079018,4.15478674 L13.7839626,4.22178402 L13.7839626,4.22178402 L13.868248,4.32297611 L13.868248,4.32297611 L13.9265675,4.42269815 L13.9265675,4.42269815 L13.9681121,4.52811787 L13.9681121,4.52811787 L13.9911767,4.62547539 L13.9911767,4.62547539 L14.0015268,4.75 L14.0015268,9.25389045 C14.0015268,9.66810401 13.6657404,10.0038905 13.2515268,10.0038905 C12.871831,10.0038905 12.5580358,9.72173657 12.5083734,9.35566101 L12.5015268,9.25389045 L12.501,6.561 L8.28044397,10.7841066 C8.01406767,11.0505974 7.59709422,11.074734 7.30343733,10.8565792 L7.21931368,10.7838641 L5.74855033,9.31112282 L3.28043609,11.7802241 C2.98760143,12.0731758 2.51272771,12.0732708 2.21977594,11.7804361 C1.95345616,11.5142228 1.92916681,11.0975639 2.14696257,10.8039089 L2.21956391,10.7197759 L5.21836468,7.71977594 C5.48474214,7.45329196 5.90171068,7.42915884 6.19536435,7.64731194 L6.2794871,7.72002639 L7.75024256,9.19275974 L11.441,5.5 L8.75,5.5 C8.37030423,5.5 8.05650904,5.21784612 8.00684662,4.85177056 L8,4.75 C8,4.33578644 8.33578644,4 8.75,4 Z" class="color212121 svgShape"></path></svg>
                </div>
                <span className="side-name">Profit Account3</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/connectexchange">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="Swap"><path d="M14 2.256V30c-2.209 0-4-1.791-4-4V13H4.714c-.633 0-.949-.765-.502-1.212l9.607-9.607C13.886 2.114 14 2.162 14 2.256zM27.788 20.212l-9.6 9.6C18.118 29.882 18 29.832 18 29.734V2c2.209 0 4 1.791 4 4v13h5.286C27.918 19 28.235 19.765 27.788 20.212z" fill="#7c8395" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Exchange</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem> */}
        {/* <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/opportunities">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-side"
                    viewBox="0 0 46.21 46.79"
                    id="Opportunities"
                  >
                    <path
                      fill="#595b5e"
                      d="M20.34 31.86c-.15.21-.29.44-.44.66a22.22 22.22 0 0 0-2.72 6.08.34.34 0 0 1-.62.08 15.28 15.28 0 0 0-8.48-6.79.33.33 0 0 0-.44.32v1.06a.34.34 0 0 1-.54.27l-3.22-2.38L.14 28.4a.34.34 0 0 1 0-.55l5-3.71 2-1.44a.34.34 0 0 1 .54.27v1.23a.33.33 0 0 0 .26.33 22.29 22.29 0 0 1 12.4 7.33zm25.73-3.73a.34.34 0 0 1 0 .54l-3.74 2.76-3.23 2.39a.34.34 0 0 1-.54-.27v-1.09a.34.34 0 0 0-.44-.32 15.29 15.29 0 0 0-10.69 14.59v.06h-7.09v-.06a22.41 22.41 0 0 1 18-22 .32.32 0 0 0 .27-.32v-1.22a.34.34 0 0 1 .54-.27l1.94 1.44zM28.37 7.58h-1.24a.34.34 0 0 0-.35.32v16a.31.31 0 0 1-.15.25 23.28 23.28 0 0 0-3.18 2.58.35.35 0 0 1-.5 0 22.69 22.69 0 0 0-3.37-3 .31.31 0 0 1-.13-.25V7.9a.34.34 0 0 0-.35-.32h-1.4a.33.33 0 0 1-.28-.52l2-2.54L22.87.18l.15-.18.15.18 3.6 4.53 1.88 2.35a.33.33 0 0 1-.28.52z"
                      class="color231f20 svgShape"
                    ></path>
                  </svg>
                </div>
                <span className="side-name">Opportunities</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem> */}

     <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/pair_management">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Pair Settings</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/bot-settings">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Bot Settings</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/investment-settings">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Investment Settings</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/loginhistory">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Login History</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/tradehistory">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="flshdash_activestatus" viewBox="0 0 32 32" id="RightArrow"><path d="M13.8 24.196a1 1 0 0 0 1.414 0L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 0 1.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Trade History</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem> */}
        {/* <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/subscription">
              <div className="chat-optionside">
                <div className="menu_items_fex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-side"
                    viewBox="0 0 35 35"
                    id="Order"
                  >
                    <path
                      d="M27.44,32.75H7.57a5.32,5.32,0,0,1-5.32-5.32V7.56A5.32,5.32,0,0,1,7.57,2.25H27.44a5.31,5.31,0,0,1,5.31,5.31V27.43A5.32,5.32,0,0,1,27.44,32.75ZM7.57,4.75A2.81,2.81,0,0,0,4.75,7.56V27.43a2.82,2.82,0,0,0,2.82,2.82H27.44a2.81,2.81,0,0,0,2.81-2.82V7.56a2.81,2.81,0,0,0-2.81-2.81Z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                    <path
                      d="M13.52 18.75a2.42 2.42 0 0 1-1-.22 2.49 2.49 0 0 1-1.46-2.29V3.5a1.25 1.25 0 0 1 2.5 0V16.24l2.35-2.08a2.41 2.41 0 0 1 3.21 0l2.41 2.09L21.45 3.5a1.25 1.25 0 0 1 2.5 0V16.24a2.49 2.49 0 0 1-1.46 2.29 2.41 2.41 0 0 1-2.61-.39L17.5 16.08l-2.38 2.07A2.41 2.41 0 0 1 13.52 18.75zm3.94-2.7zm.09 0zM19 26.57h-9a1.25 1.25 0 1 1 0-2.5h9a1.25 1.25 0 0 1 0 2.5z"
                      fill="#595b5e"
                      class="color000000 svgShape"
                    ></path>
                  </svg>
                </div>
                <span className="side-name">Subscription</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding onClick={logout}>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <div className="side-lo-div" >
              <div className="chat-optionside">
                <div className="menu_items_fex">
                <svg xmlns="http://www.w3.org/2000/svg" className="head-logot-chang" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#f3f3f3" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                <span className="side-name">Logout</span>
              </div>
            </div>
          </ListItemButton> 
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>

            <div className="drawer-head">
              <div className="header-res-menu">
                <div className="landing-header1">
                  <img
                    src={require("../images/daulah_holding_logo.png")}
                    alt="logo"
                    className="logo-img"
                  />
                </div>
              </div>
              <div className="header-res-menu">
                <div className="header-right-one cursor-pointer">
                  <span className="header-right-onespan"> En</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-small"
                    viewBox="0 0 48 48"
                    id="Dropdown"
                  >
                    <path
                      d="m14 20 10 10 10-10z"
                      fill="#8b8c8d"
                      class="color000000 svgShape"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
                <div className="mx-2 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" className="head-logot-chang" viewBox="0 0 24 24" id="Notification"><path d="M12 23a2 2 0 0 0 2-2H10A2 2 0 0 0 12 23zM4 19H20a1 1 0 0 0 .707-1.707L19 15.586V10a7.006 7.006 0 0 0-6-6.92V2a1 1 0 0 0-2 0V3.08A7.006 7.006 0 0 0 5 10v5.586L3.293 17.293A1 1 0 0 0 4 19zm2.707-2.293A1 1 0 0 0 7 16V10a5 5 0 0 1 10 0v6a1 1 0 0 0 .293.707l.293.293H6.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </div>
                <div className="mx-2 cursor-pointer" onClick={logout}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="head-logot-chang" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#f3f3f3" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                  </div>
                <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
                <MenuIcon />
          </IconButton>

              </div>
            </div>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <div className="landing-header1">
              <img
                src={require("../images/daulah_holding_logo.png")}
                alt="logo"
                className="logo-img"
              />
            </div>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <>
              <Link to="/">
                <Button sx={{ color: "#fff" }}>
                  <div className="header-right-one">
                    <span className="header-right-onespan"> En</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-small"
                      viewBox="0 0 48 48"
                      id="Dropdown"
                    >
                      <path
                        d="m14 20 10 10 10-10z"
                        fill="#8b8c8d"
                        class="color000000 svgShape"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                </Button>
              </Link>

              <Link to="/">
                <Button sx={{ color: "#fff" }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="head-logot-chang" viewBox="0 0 24 24" id="Notification"><path d="M12 23a2 2 0 0 0 2-2H10A2 2 0 0 0 12 23zM4 19H20a1 1 0 0 0 .707-1.707L19 15.586V10a7.006 7.006 0 0 0-6-6.92V2a1 1 0 0 0-2 0V3.08A7.006 7.006 0 0 0 5 10v5.586L3.293 17.293A1 1 0 0 0 4 19zm2.707-2.293A1 1 0 0 0 7 16V10a5 5 0 0 1 10 0v6a1 1 0 0 0 .293.707l.293.293H6.414z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                </Button>
              </Link>

              <Link to="/" onClick={logout}>
                <Button sx={{ color: "#fff" }}>
                  <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="head-logot-chang" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#f3f3f3" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#f3f3f3" class="color000000 svgShape"></path></svg>
                  </div>
                </Button>
              </Link>
            </>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
